import Vivus from "vivus";

export default function animateSubtitle(id) {
    const el = document.querySelector(`#${id}`);

    if (el) {
        el.classList.add("show");
        new Vivus(id, {
            duration: 3700,
            forceRender: false,
        });

        setTimeout(() => { 
            el.classList.add("complete");
        }, 2500);
    }
    
    document.querySelector('.page-intro__subtitle').classList.add('active');
}

