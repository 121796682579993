import "leaflet";

export default function streetMap() {
    let container = L.DomUtil.get("mymap");
    if (container != null) {
        container._leaflet_id = null;
    }
    let mymap = L.map("mymap").setView(
        [45.54216502885837, 10.298952347849621],
        16
    );
    let myIcon = L.icon({
        iconUrl: "/wp-content/themes/ar-vallalta/assets/i/static/map-icon.png",
        iconSize: [44, 56],
        iconAnchor: [22, 66],
        shadowSize: [68, 95],
        shadowAnchor: [22, 94],
    });

    let marker = L.marker([45.54216502885837, 10.298952347849621], {
        icon: myIcon,
        title: "Via della Marogna, 1 25082 Botticino BS, Italia",
    })
        .bindPopup(
            `<a href="https://www.google.com/maps/dir//Societ%C3%A0+agricola+Vallalta,+Via+della+Marogna,+1,+25082+Botticino+BS,+Italy/@45.5422222,10.2967002,17z/data=!4m16!1m6!3m5!1s0x47819c4d915d9915:0x7c83d389c75b55fd!2sSociet%C3%A0+agricola+Vallalta!8m2!3d45.5422187!4d10.2988891!4m8!1m0!1m5!1m1!1s0x47819c4d915d9915:0x7c83d389c75b55fd!2m2!1d10.2988891!2d45.5422187!3e3" target="_blank" rel="noopener noreferrer">Usa il navigatore</a>`
        )
        .addTo(mymap);

    L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
        maxZoom: 24,
        icon: "/wp-content/themes/bs-micci-giovani/assets/i/static/map-icon.svg",
        id: "mapbox/streets-v11",
        tileSize: 512,
    }).addTo(mymap);
}
