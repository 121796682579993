import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export default function pagesAnimation() {
    function ingrAnimation(el, ind) {
        gsap.from(el, {
            scrollTrigger: {
                start: "top: 100%",
                trigger: el,
                scrub: ind + 2,
            },
            yPercent: -10 * (ind + 1),
        });
    }

    const ingredients = document.querySelectorAll(".menu-section__dec");

    for (let ind = 0; ind < ingredients.length; ind++) {
        const el = ingredients[ind];
        ingrAnimation(el, ind);
    }
}
