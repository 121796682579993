import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import animateSubtitle from './animate-subtitle'

export default function gsapAnimation() {
  gsap.registerPlugin(ScrollTrigger)

  function introAnimation() {
    let tl = gsap.timeline({
      defaults: { duration: 2.5, ease: 'power3.out' },
    })

    tl.to('.header__logo img', {
      duration: 2.5,
      opacity: 1,
      scale: 10,
      x: '44vw',
      y: '44vh',
      ease: 'power3.out',
    })
    tl.to(
      '.header__logo img',
      {
        duration: 2,
        scale: 1,
        x: '0',
        y: '0',
      },
      '-=1'
    )

    // tl.to(
    //   ".home-intro__subtitle",
    //   {
    //     duration: 2,
    //     x: 0,
    //   },
    //   "-=1"
    // );

    tl.to(
      '.home-intro__title',
      {
        duration: 5,
        opacity: 1,
        '--scale': '1',
      },
      '-=1.5'
    )

    tl.to(
      '.home-intro__title',
      {
        duration: 5,
        '--width': '100%',
      },
      '<'
    )

    tl.to(
      `.home-intro__title .section-title__bg`,
      {
        duration: 2,
        width: 0,
      },
      '<'
    )
    if (document.querySelector('.page-intro__subtitle')) {
      tl.call(animateSubtitle, ['introTitle'], '<')
    }

    tl.to('.home-intro__more', { x: 0 }, '<')

    tl.to(
      '.home-intro__img',
      {
        duration: 15,
        opacity: 1,
      },
      '-=4.5'
    )
    // tl.to(
    //   ".home-intro__hover",
    //   {
    //     duration: 15,
    //     opacity: 1,
    //   },
    //   "<"
    // );
  }

  if (document.querySelector('.home-intro__bg')) {
    introAnimation()
  }

  function sectionAnimation(id, idSvg, direction) {
    if (document.querySelector(id)) {
      let tl = gsap.timeline({
        scrollTrigger: {
          start: 'top: 100%',
          trigger: id,
        },
        defaults: {
          duration: 2.5,
          ease: 'power2.out',
        },
      })
      if (document.querySelector('.page-intro__subtitle')) {
        tl.call(animateSubtitle, [idSvg])
      }

      tl.fromTo(
        '.section__title',
        {
          opacity: 0,
          y: -100,
        },
        {
          opacity: 1,
          duration: 1,
          y: 0,
        }
      )

      titleAnimation(tl, id, 2)
      tl.to(
        `${id} .section__img`,
        {
          '--x': direction == 'left' ? '100%' : '-100%',
        },
        '<'
      )

      tl.to(
        `${id} .section__img img`,
        {
          scale: 1,
          duration: 4,
        },
        '<'
      )

      tl.from(
        `${id} .section__text p`,
        {
          opacity: 0,
          y: 60,
          duration: 4,
          stagger: 0.4,
        },
        '-=4.5'
      )
      tl.from(
        `${id} .section__cta`,
        {
          opacity: 0,
          y: 60,
          duration: 3,
        },
        '-=4'
      )
    }
  }

  sectionAnimation('#section1', 'sect1')
  // sectionAnimation('#section2', 'sect2', 'left')
  sectionAnimation('#section3', 'sect3')

  function mainSliderAnimation(id, idSvg, direction) {
    if (document.querySelector(id)) {
      let tl = gsap.timeline({
        scrollTrigger: {
          start: 'top: 100%',
          trigger: id,
        },
        defaults: {
          duration: 2.5,
          ease: 'power2.out',
        },
      })
      if (document.querySelector('.page-intro__subtitle')) {
        tl.call(animateSubtitle, [idSvg])
      }
      titleAnimation(tl, id, 2)

      tl.to(
        `${id} .main-slider__wrapper`,
        {
          '--x': direction == 'left' ? '100%' : '-100%',
        },
        '<'
      )

      tl.from(
        `${id} .slider-btn`,
        {
          opacity: 0,
          stagger: 0.5,
        },
        '-=1'
      )
      tl.from(
        `${id} .text`,
        {
          opacity: 0,
        },
        '<'
      )
    }
  }

  mainSliderAnimation('#main-slider', 'totry', 'left')

  function footerAnimation() {
    let tl = gsap.timeline({
      scrollTrigger: {
        start: 'top: 100%',
        trigger: '.footer__subtitle',
      },
      defaults: {
        duration: 2.5,
        ease: 'power2.out',
      },
    })
    titleAnimation(tl, '.footer__top', 3)
  }

  if (document.querySelector('.footer__subtitle')) {
    footerAnimation()
  }

  function videoAnimation() {
    let tl = gsap.timeline({
      scrollTrigger: {
        start: 'top: 100%',
        trigger: '.video__title',
      },
      defaults: {
        duration: 2.5,
        ease: 'power2.out',
      },
    })
    titleAnimation(tl, '.video__text', 2)
  }

  if (document.querySelector('.video__text')) {
    videoAnimation()
  }

  function titleAnimation(tl, el, dur) {
    tl.to(`${el} .section-title`, {
      duration: 1,
      opacity: 1,
      '--scale': '1',
    })
    tl.to(
      `${el} .section-title`,
      {
        duration: dur,
        '--width': '100%',
      },
      '<'
    )
    tl.to(
      `${el} .section-title__bg`,
      {
        duration: dur,
        width: 0,
      },
      '<'
    )
  }
}
