export default function videoCtrl() {
    const ctrlVideo = document.getElementById("bannerVideo");
    if (ctrlVideo) {
        const playBtn = document.querySelector(".video__start");
        const videoContainer = document.querySelector(".video__file");

        playBtn.addEventListener("click", function () {
            if (playBtn.classList.contains("active")) {
                ctrlVideo.play();
            } else {
                ctrlVideo.pause();
            }
            playBtn.classList.toggle("active");
            videoContainer.classList.toggle("active");
        });

        ctrlVideo.addEventListener("ended", function () {
            playBtn.classList.add("active");
            videoContainer.classList.remove("active");
        });
    }
}
