import '../../src/scss/my.scss'
// import "./modules/libs/popup";
import './modules/sliders'

import gsapAnimation from './modules/animations/animation'

import activeMenuOnProductPage from './modules/activeMenuOnProductPage'
// import productCategoriesAnimation from "./modules/animations/product-categories-animation";

import * as flsFunctions from './modules/functions.js'
import menu from './modules/menu'
import streetMap from './modules/maps/streetMap'
import * as flsScroll from './modules/scroll/scroll.js'
import pagesAnimation from './modules/animations/pages-animation'
import videoCtrl from './modules/videoCtrl'
import quantityBtns from './modules/woocommerce/quantity-btns'
import headerFixed from './modules/headerFixed'
import pageIntroAnimation from './modules/animations/page-intro-animation'

// import "./modules/libs/watcher.js";

document.addEventListener('DOMContentLoaded', function () {
  // window["FLS"] = true;

  if (window.document.documentMode) {
    // alert('Internet Explorer');
    console.log('internet expolorer')
  } else {
    let resized = false
    window.addEventListener('resize', function () {
      if (window.innerWidth > 895 && !resized) {
        gsapAnimation()
        resized = true
      }
    })
    if (window.innerWidth > 895) {
      gsapAnimation()
      resized = true
    }
  }
  if (
    window.innerWidth > 992 &&
    document.querySelector('.page-intro__subtitle')
  ) {
    pageIntroAnimation()
  }

  if (document.body.classList.contains('single-product')) {
    activeMenuOnProductPage()
  }

  flsScroll.pageNavigation()

  if (
    document.querySelector('.product-categories-custom') &&
    window.innerWidth > 992
  ) {
    productCategoriesAnimation()
  }

  menu()
  headerFixed()

  // streetMap()
  videoCtrl()

  // flsFunctions.showMore()
  flsFunctions.spollers()

  setTimeout(function () {
    quantityBtns()
    pagesAnimation()
  }, 100)
})
