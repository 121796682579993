import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

export default function pageIntroAnimation() {
    gsap.registerPlugin(ScrollTrigger);

        let tl = gsap.timeline();
        tl.fromTo(
          ".page-intro__subtitle",
          {
            opacity: 0,
            y:-750
          },
          {
              y:0,
            opacity: 1,
            duration: 1.3,
            ease: "power1.inOut",
          },
        );
}