import { bodyLockStatus, bodyLock, bodyUnlock } from "./functions";

export default function menu() {
    //=================
    //Menu
    let iconMenu = document.querySelector(".icon-menu");
    let logo = document.querySelector(".header__logo");
    const header = document.querySelector(".header");

    if (iconMenu != null) {
        let delay = 500;
        let menuBody = document.querySelector(".menu__body");
        iconMenu.addEventListener("click", function (e) {
            if (bodyLockStatus) {
                if (iconMenu.classList.contains("_active")) {
                    bodyUnlock(0);
                    iconMenu.classList.remove("_active");
                    menuBody.classList.remove("_active");
                    logo.classList.remove("_active");
                    header.classList.remove("_active");
                } else {
                    bodyLock(delay);
                    iconMenu.classList.add("_active");
                    menuBody.classList.add("_active");
                    logo.classList.add("_active");
                    header.classList.add("_active");
                }
            }
        });
    }
    function menu_close() {
        let iconMenu = document.querySelector(".icon-menu");
        let menuBody = document.querySelector(".menu__body");
        iconMenu.classList.remove("_active");
        menuBody.classList.remove("_active");
        logo.classList.remove("_active");
        header.classList.remove("_active");
    }

    window.addEventListener("load", function () {
        if (document.querySelector(".wrapper")) {
            setTimeout(function () {
                document.querySelector(".wrapper").classList.add("_loaded");
            }, 0);
        }
    });

    const menuLinks = document.querySelectorAll(".menu__link");

    menuLinks.forEach(function (link) {
        link.addEventListener("click", function () {
            bodyUnlock(0);
            menu_close();
        });
    });
}
