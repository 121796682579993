export default function quantityBtns() {
    const quantityCtrl = document.querySelectorAll(".quantity-ctrl");

    if (quantityCtrl.length) {
        for (let ind = 0; ind < quantityCtrl.length; ind++) {
            const ctrl = quantityCtrl[ind];

            const input = ctrl.querySelector('input[type="number"]');
            const btnMinus = ctrl.querySelector(".quantity-ctrl__btn.minus");
            const btnPlus = ctrl.querySelector(".quantity-ctrl__btn.plus");

            btnMinus.addEventListener("click", function (e) {
                e.preventDefault();
                updateInput("-", input);
            });
            btnPlus.addEventListener("click", function (e) {
                e.preventDefault();
                updateInput("+", input);
            });
        }
    }
}

function updateInput(sign, input) {
    let inputValue = +input.value;

    if (sign == "+") {
        input.value = ++inputValue;
    } else if (sign == "-" && inputValue > 1) {
        input.value = --inputValue;
    }

    if (input.closest(".woocommerce-cart-form")) {
        const updBtn = document.querySelector('button[name="update_cart"]');

        updBtn.removeAttribute("disabled");
        updBtn.setAttribute("aria-disabled", false);
    }
}
